<template>
	<div class="app-container" :style="'height:'+height+'px'">
		<custom-nav-top></custom-nav-top>
		<div class="content">
			<el-row :gutter="24" style="margin: 0;padding: 0; height: 100%;">

				<el-col :span="4" style="margin: 0;padding: 0; height: 100%;">
					<div class="tab">
						<div v-for="(item,index) in tagslist" :key="index" class="tab-item"
							:class="currentTagIndex == index ? 'tab-current-item':''" @click="handleTabClick(index)">
							<img class="tab-img" :src="item.src" />
							<span class="label">{{item.label}}</span>
						</div>
					</div>
				</el-col>

				<el-col :span="1" style="margin: 0;padding: 0;height: 100%;">
					<div class="arrow">
						<template v-if="upActive">
							<img src="@/assets/image/arrow-up-active.png" @click="handleUpClick" class="arrow-img" />
						</template>
						<template v-else>
							<img src="@/assets/image/arrow-up.png" class="arrow-img" />
						</template>
						<template v-if="downActive">
							<img src="@/assets/image/arrow-down-active.png" class="arrow-img"
								@click="handleDownClick" />
						</template>
						<template v-else>
							<img src="@/assets/image/arrow-down.png" class="arrow-img" />
						</template>
					</div>
				</el-col>

				<el-col :span="19" style="margin: 0;padding: 0;height: 100%;">
					<div class="tab-content">
						<template v-if="currentTagIndex == 0">
							<div class="action">
								<img src="@/assets/image/translate.png">
							</div>
							<div class="video">
								<div class="box">
									<video id="video" class="video-bx" loop="loop" muted="muted" autoplay="autoplay">
										<source src="https://img.vanjiax.com/jiuZiYan/video/item05.mp4"
											type="video/mp4">
									</video>
									<div class="indicator">
										<div class="indicator-box" v-for="(item,index) in indicatorlist" :key="index"
											@click="handleIndicatorClick(index)">
											<img :src="item.src" class="indicator-img"
												:style="currentIndicatorIndex == index ? 'border:1px solid '+ getColor: ''" />
											<template v-if="currentIndicatorIndex == index">
												<span>{{item.label}}</span>
											</template>
										</div>
									</div>
									<div class="title">
										<div class="subtitle">方寸间桌面花园</div>
										<div class="detail" @click="handleDetail">
											<span>了解详情</span>
											<span class="iconfont icon-right"></span>
										</div>
									</div>
								</div>
							</div>
						</template>
					</div>
				</el-col>
			</el-row>
		</div>

	</div>
</template>

<script>
	import customNavTop from '@/components/custom-nav-top.vue'
	export default {
		name: 'goodS',
		data() {
			return {
				// 标签列表
				tagslist: [{
						src: require('@/assets/image/tab-item01.png'),
						label: "方寸间桌面花园"
					}, {
						src: require('@/assets/image/tab-item02.png'),
						label: "草莓屋系列"
					},
					{
						src: require('@/assets/image/tab-item03.png'),
						label: "雪屋系列"
					}
				],
				// 指示器列表
				indicatorlist: [{
					src: require('@/assets/image/indicator01.png'),
					label: '奇妙蛙'
				}, {
					src: require('@/assets/image/indicator03.png'),
					label: '皮卡丘'
				}, {
					src: require('@/assets/image/indicator02.png'),
					label: '萌小咪'
				}, {
					src: require('@/assets/image/indicator04.png'),
					label: '四季鹿'
				}],
				// 当前指示器索引
				currentIndicatorIndex: 0,
				// 当前标签索引
				currentTagIndex: 0,
				// 是否展示向上激活
				upActive: false,
				// 是否展示向下激活
				downActive: true,
				// 指示器激活边框颜色
				borderColor: ['#4AB17D', '#D7B037', '#DB6A60', '#EE7C33'],
				// 展示视频列表
				videolist: [
					'https://img.vanjiax.com/jiuZiYan/video/item05.mp4',
					'https://img.vanjiax.com/jiuZiYan/video/item02.mp4',
					'https://img.vanjiax.com/jiuZiYan/video/item03.mp4',
					'https://img.vanjiax.com/jiuZiYan/video/item04.mp4'
				],
				height: null
			}
		},
		components: {
			customNavTop
		},
		computed: {
			getColor() {
				return this.borderColor[this.currentIndicatorIndex]
			}
		},
		created() {
			this.height = document.documentElement.clientHeight;
			window.addEventListener('resize', this.handleResize)
		},
		methods: {
			handleTabClick(index) {
				if (index == 0) {
					this.currentTagIndex = index
				} else {
					return false
				}
			},
			handleUpClick() {
				if (this.currentTagIndex == 1) {
					this.upActive = false;
				}
				this.downActive = true
			},
			handleDownClick() {
				if (this.currentTagIndex == 2) {
					this.downActive = false
				}
				this.upActive = true;
			},
			handleIndicatorClick(index) {
				this.currentIndicatorIndex = index;
				var vd = document.getElementById('video');
				vd.src = this.videolist[this.currentIndicatorIndex];
				vd.play()
			},
			handleDetail() {
				this.$router.push({
					path: '/typeSDetail',
					query: {
						index: this.currentIndicatorIndex
					}
				});
				this.$bus.$emit('navClick', true)
			},
			handleResize() {
				window.location.reload()
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.app-container {
		display: flex;
		flex-direction: column;
		overflow-y: hidden;

		.content {
			flex: 1;

			.tab {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-evenly;
				align-items: flex-start;
				background-color: #F7F7F7;

				.tab-item {
					flex: auto;
					width: 100%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;

					.tab-img {
						width: 165px;
					}

					@media screen and (max-width: 1500px) {
						.tab-img {
							width: 100px;
						}
					}

					.label {
						margin-top: 15px;
						font-size: 20px;
						color: #111111;
					}

					@media screen and (max-width: 1500px) {
						.label {
							font-size: 14px;
						}
					}
				}

				.tab-current-item {
					background-color: #fff;
					border-bottom: 2px solid #4AB17D;

					.label {
						font-size: 20px;
						color: #4AB17D;
					}


				}

				@media screen and (max-width: 1500px) {

					.tab-current-item {
						.label {
							font-size: 14px;
						}


					}

				}
			}

			.arrow {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: center;

				.arrow-img {
					width: 38px;
					margin-bottom: 10px;
				}

				@media screen and (max-width:1500px) {
					.arrow-img {
						width: 24px;
					}

				}
			}

			.tab-content {
				height: 100%;
				background: url(../assets/image/tab-bg.png) no-repeat;
				background-position: right bottom;
				display: flex;

				.action {
					padding: 75px 50px;
				}

				.video {
					flex: auto;
					display: flex;
					align-items: center;

					.box {
						// margin-left: 190px;
						height: 100%;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;

						.video-bx {
							width: 900px;
							height: 600px;
						}

						.indicator {
							width: 100%;
							display: flex;
							justify-content: center;
							align-items: center;
							.indicator-box {
								width: 50px;
								height: 100%;
								margin-left: 30px;
								display: flex;
								flex-direction: column;
								align-items: center;

								.indicator-img {
									border-radius: 50%;
									padding: 1px;
								}

								span {
									margin-top: 10px;
									font-size: 16px;
									color: #666666;
								}
							}
						}

						.title {
							margin-top: 30px;
							margin-left: 30px;
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							.subtitle {
								display: flex;
								justify-content: center;
								align-items: center;
								font-size: 20px;
								color: #111111;
							}

							.detail {
								margin-top: 20px;
								background: #4AB17D;
								border-radius: 20px;
								padding: 12px 33px;
								display: flex;
								justify-content: center;
								align-items: center;

								span:first-child {
									font-size: 16px;
									color: #FFFFFF;
								}

								span:last-child {
									margin-left: 10px;
									font-size: 22px;
									color: #FFFFFF;
								}
							}
						}
					}
				}
			}

			@media screen and (max-width:1700px) {
				.tab-content {
					background-size: 30%;

					.action {
						padding: 20px 30px;

						img {
							width: 50px;
						}
					}

					.video {
						.box {
							margin-left: 170px;
							.video-bx {
								width: 500px;
								height: 300px;
							}

							.indicator {
								.indicator-box {
									.indicator-img {
										width: 25px;
									}

									span {
										font-size: 12px;
									}
								}
							}

							.title {
								.subtitle {
									font-size: 14px;
								}

								.detail {
									padding: 12px 23px;

									span:first-child {
										font-size: 14px;
									}

									span:last-child {
										font-size: 16px;
									}
								}
							}

						}
					}
				}
			}
		}
	}
</style>
