import { render, staticRenderFns } from "./goodS.vue?vue&type=template&id=641c4265&scoped=true&"
import script from "./goodS.vue?vue&type=script&lang=js&"
export * from "./goodS.vue?vue&type=script&lang=js&"
import style0 from "./goodS.vue?vue&type=style&index=0&id=641c4265&lang=scss&scoped=scoped&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "641c4265",
  null
  
)

export default component.exports